<div class="card-container">
    <div>
        <h2 *ngIf="(isProductPackage && !isProposeRequest) || !isProductPackage">
            {{ isProductPackage ? cardTitle : cardTitleLabel}}
        </h2>
        <p *ngIf="isCxadvisory && ((!isProposeRequest && isProductPackage) || (!isProductPackage) )">
            {{cardSubTitle}}
        </p>
        <mat-card-content>
            <div [class.margin-vertical]="(isProductPackage && !isProposeRequest) || !isProductPackage">
                <ucba-select-document [control]="form.controls['acceptedSampleCalculationFileId']"
                    [selectedId]="userAcceptedSampleCalculationFileId | async" [array]="dataSource"
                    (selectedChanged)="selectedValueChanged.emit($event)"
                    (buttonClicked)="openFileClicked.emit($event)">
                </ucba-select-document>
            </div>
            <div class="margin-vertical">
                <ucba-textarea [dynamicRows]="true" [rows]="3" [control]="form.controls['sampleCalculationResponse']"
                    [cxAdvisory]="isCxadvisory" [labelText]="responseLabel">
                </ucba-textarea>
            </div>
        </mat-card-content>
    </div>
    <button *ngIf="hasSelectedId(userAcceptedSampleCalculationFileId | async)" mat-flat-button color="primary"
        (click)="sendCreditOfferDecisionClicked.emit({sampleCalculationResponse: form.controls['sampleCalculationResponse'].value, accepted: !isProposeRequest})">
        {{ isProposeRequest ? sendProposeRequestBtnLabel : sendAcceptedBtnLabel }}
    </button>
</div>