<mat-radio-group *ngIf="!!control && array" [formControl]="formControl">
    <div fxLayout="column" *ngFor="let entity of array">
        <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-radio-button fxFlex="100" [value]="entity.value" (change)="onSelectedChanged(entity.value)"
                color="primary" [checked]="entity.value === selectedId">
                <mat-option>
                    {{entity.label}}
                </mat-option>
            </mat-radio-button>
            <button *ngIf="!!entity.iconName" mat-icon-button (click)="onButtonClicked(entity.value)"
                matTooltip="Rechenbeispiel anzeigen" type="button">
                <mat-icon>{{entity.iconName}}</mat-icon>
            </button>
        </div>
        <hr>
    </div>
</mat-radio-group>