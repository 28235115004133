<mat-card class="cardStyle mat-elevation-z2" *ngIf="financingCardModel" [ngClass]="cxAdvisory ? 'cxad' : ''">
    <mat-card-header>
        <mat-card-title *ngIf="financingCardModel.type !== undefined && financingCardModel.type !== null; else noType">
            {{ financingCardModel.type | finprocessEnumTranslation:'RealEstateType' }}
        </mat-card-title>
        <ng-template #noType>
            <mat-card-title>&nbsp;</mat-card-title>
        </ng-template>
        <div class="content-buttons">
            <div *ngIf="buttons.length === 1 || forceExpand" fxLayout="row">
                <button *ngFor="let btn of buttons" mat-icon-button (click)="btn.onClick(financingCardModel.id)"
                    [matTooltip]="btn.tooltip">
                    <mat-icon [matBadge]="btn.badge" matBadgeSize="small" class="material-icons">
                        {{ btn.icon }}
                    </mat-icon>
                </button>
            </div>
            <button *ngIf="buttons.length > 1 && !forceExpand" [matMenuTriggerFor]="menu"
                aria-label="Toggle menu" mat-button
                matTooltip="{{ 'components.financingCard.openFinancingActions' | translate }}">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before" [class]="'customize'">
                <button *ngFor="let btn of buttons" mat-icon-button (click)="btn.onClick(financingCardModel.id)"
                    [matTooltip]="btn.tooltip">
                    <mat-icon [matBadge]="btn.badge" matBadgeSize="small" class="material-icons">
                        {{ btn.icon }}
                    </mat-icon>
                </button>
            </mat-menu>
        </div>
    </mat-card-header>
    <hr class="financing-card-underline">
    <mat-card-content class="cursor-pointer" (click)="onClicked()"
        matTooltip="{{'components.financingCard.openFinancing' | translate}}" matRipple fxLayout="column"
        fxLayoutAlign="space-between stretch">
        <div fxLayout="column">
            <div fxLayout="row">
                <div class="col">{{'components.financingCard.address' | translate}}</div>
                <div class="col">{{ formatAddress }}</div>
            </div>
            <div fxLayout="row">
                <div class="col">{{'components.financingCard.creditAmount' | translate}}</div>
                <div class="col">{{ financingCardModel.creditAmount | currency:'EUR':'symbol':'1.2-2' }}</div>
            </div>
            <div fxLayout="row">
                <div class="col">{{'components.financingCard.created' | translate}}</div>
                <div class="col">{{ financingCardModel.created | date:'short'}}</div>
            </div>
            <div fxLayout="row">
                <div class="col">{{'components.financingCard.modified' | translate}}</div>
                <div class="col">{{ financingCardModel.modified | date:'short'}}</div>
            </div>
            <div *ngIf="isDurationVisible" fxLayout="row">
                <div class="col">{{'components.financingCard.processingTimeText' | translate}}</div>
                <div class="col">{{ processingTimeText }}</div>
            </div>
            <div *ngIf="!!financingCardModel.processingActuallyStart" fxLayout="row">
                <div class="col">{{'components.financingCard.processingActuallyStart' | translate}}</div>
                <div class="col">{{ financingCardModel.processingActuallyStart | date:'dd.MM.yyyy'}}</div>
            </div>
            <div fxLayout="row">
                <div class="col">{{'components.financingCard.status' | translate}}</div>
                <div *ngIf="!!financingCardModel.alternativeStatusText" class="col">
                    {{ financingCardModel.alternativeStatusText }}
                </div>
                <div *ngIf="financingCardModel.status !== null && financingCardModel.status !== undefined && !financingCardModel.alternativeStatusText"
                    class="col">
                    {{(financingCardModel.status |finprocessEnumTranslation:'FinancingMapStatus') }}
                    <span *ngIf="financingCardModel.isAutoExit">
                        &nbsp;(automatisch)
                    </span>
                </div>
            </div>
            <div *ngIf="!!financingCardModel.statusInfo" fxLayout="row" fxLayoutAlign="start center">
                <div class="col">{{'components.financingCard.statusInfo' | translate}}</div>
                <div class="col" fxLayout="row" fxLayoutAlign="start center"> {{ financingCardModel.htmlStatusInfoShort
                    }}
                    <span fxLayoutAlign="start center" *ngIf="financingCardModel.htmlStatusInfoLong.length > maxLength"
                        class="more-button" (mouseenter)="toggleVisibility(true)" (mouseleave)="toggleVisibility(false)"
                        (click)="onExpandClicked($event, 'Info zum Status', financingCardModel.htmlStatusInfoLong)"
                        matTooltip="Mehr anzeigen">
                        <mat-icon>{{visible ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </span>
                </div>
            </div>
            <div fxLayout="row">
                <div class="col">{{'components.financingCard.clerkDisplayName' | translate}}</div>
                <div class="col">{{ financingCardModel.clerkDisplayName }}</div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-flat-button type="button" color="primary">
                {{'components.financingCard.enter' | translate}}
            </button>
        </div>
    </mat-card-content>
</mat-card>