<mat-dialog-content class="mat-typography">
    <div class="rate-calculation-container">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true" type="button">
            <mat-icon color="primary">close</mat-icon>
        </button>
        <h1 fxHide fxHide.gt-sm="false" class="rate-calculation-title">{{'components.calculationDialog.rateCalculator' |
            translate}}</h1>
        <h2 fxHide.gt-sm class="rate-calculation-title">{{'components.calculationDialog.rateCalculator' | translate}}
        </h2>
        <form *ngIf="form" [formGroup]="form">
            <div class="rate-calculation-slide">
                <div class="input-wrapper">
                    <label class="slider-label">
                        {{'components.calculationDialog.constructionCreditAmount' | translate}}
                    </label>
                    <div class="input-control-container">
                        <button mat-button class="btn" (mousedown)="startContinuousClick('amount', 'subtract')"
                            (mouseup)="stopContinuousClick()" (mouseleave)="stopContinuousClick()">
                            <mat-icon>remove</mat-icon>
                        </button>
                        <ucba-input-currency class="slider-input" [ignoreRequireStar]="true"
                            [control]="form.controls['amount']">
                        </ucba-input-currency>
                        <button mat-button class="btn" (mousedown)="startContinuousClick('amount', 'add')"
                            (mouseup)="stopContinuousClick()" (mouseleave)="stopContinuousClick()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="asFormControl('amount') as amountCtl">
                    <mat-slider [step]="stepAmount" [min]="minAmount" [max]="maxAmount" discrete
                        [displayWith]="formatLabelAmount" aria-label="units">
                        <input matSliderThumb (input)="onChangeCredit(ngSliderThumb.value)"
                            [value]="form.controls['amount'].value" #ngSliderThumb="matSliderThumb"
                            title="{{'components.calculationDialog.constructionCreditAmount' | translate}}" />
                    </mat-slider>
                    <div class="slider-values">
                        <label class="slider-min">{{minAmount | currency:'EUR':'symbol':'1.2-2'}}</label>
                        <label class="slider-max">{{maxAmount | currency:'EUR':'symbol':'1.2-2'}}</label>
                    </div>
                </div>
            </div>
            <div class="rate-calculation-slide">
                <div class="input-wrapper">
                    <label class="slider-label">
                        {{'components.calculationDialog.constructionCreditDebitRate' | translate}}
                    </label>
                    <div class="input-control-container">
                        <button mat-flat-button class="btn" (mousedown)="startContinuousClick('debitRate', 'subtract')"
                            (mouseup)="stopContinuousClick()" (mouseleave)="stopContinuousClick()">
                            <mat-icon>remove</mat-icon>
                        </button>
                        <ucba-input-currency class="slider-input" [ignoreRequireStar]="true"
                            [type]="currencyInputType.PERCENT" [control]="form.controls['debitRate']">
                        </ucba-input-currency>
                        <button mat-flat-button class="btn" (mousedown)="startContinuousClick('debitRate', 'add')"
                            (mouseup)="stopContinuousClick()" (mouseleave)="stopContinuousClick()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="asFormControl('debitRate') as debitCtl">
                    <mat-slider [step]="stepDebitRate" [min]="minDebitRate" [max]="maxDebitRate" discrete
                        [displayWith]="formatLabelDebitRate" aria-label="units">
                        <input matSliderThumb (input)="onChangeDebitRate(ngSliderThumb.value)"
                            title="{{'components.calculationDialog.constructionCreditDebitRate' | translate}}"
                            [value]="form.controls['debitRate'].value" #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="slider-values">
                        <label class="slider-min">{{minDebitRate}}%</label>
                        <label class="slider-max">{{maxDebitRate}}%</label>
                    </div>
                </div>
            </div>
            <div class="rate-calculation-slide">
                <div class="input-wrapper">
                    <label class="slider-label">
                        {{'components.calculationDialog.constructionCreditDuration' | translate}}
                    </label>
                    <div class="input-control-container">
                        <button mat-flat-button class="btn" (mousedown)="startContinuousClick('duration', 'subtract')"
                            (mouseup)="stopContinuousClick()" (mouseleave)="stopContinuousClick()">
                            <mat-icon>remove</mat-icon>
                        </button>
                        <ucba-input-currency class="slider-input" [ignoreRequireStar]="true"
                            [type]="currencyInputType.SUFFIX" suffix=" Jahre" [control]="form.controls['duration']">
                        </ucba-input-currency>
                        <button mat-flat-button class="btn" (mousedown)="startContinuousClick('duration', 'add')"
                            (mouseup)="stopContinuousClick()" (mouseleave)="stopContinuousClick()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="asFormControl('duration') as durationCtl">
                    <mat-slider [step]="stepDuration" [min]="minDuration" [max]="maxDuration" discrete
                        [displayWith]="formatLabelDuration" aria-label="units">
                        <input matSliderThumb (input)="onChangeCreditDuration(ngSliderThumb.value)"
                            title="{{'components.calculationDialog.constructionCreditDuration' | translate}}"
                            [value]="form.controls['duration'].value" #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="slider-values">
                        <label class="slider-min">{{minDuration}} {{'global.years' | translate}}</label>
                        <label class="slider-max">{{maxDuration}} {{'global.years' | translate}}</label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="monthly-rate-container">
        <label class="monthly-rate-left">{{'components.calculationDialog.monthlyRate' |
            translate}}</label>
        <label class="monthly-rate-right">{{monthlyRate | currency:'EUR':'symbol':'1.2-2'}}</label>
    </div>
</mat-dialog-content>