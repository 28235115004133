import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ValidationService } from '../../services/validation/validation.service';

// see libs\components\src\lib\enums\currency-input-type.enum.ts
export enum CurrencyInputType {

    /** #,## € */
    CURRENCY = 0,

    /** #,# % */
    PERCENT = 1,

    /** #,##m² */
    AREA = 2,

    /** #[SUFFIX] */
    SUFFIX = 4,
}

/**
 * Komponente, welche eine currency Input Feld darstellt
 */
@Component({
    selector: 'ucba-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.scss'],
})
export class InputCurrencyComponent implements OnInit, OnDestroy {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public type = CurrencyInputType.CURRENCY;

    /** der Stern bei Pflichtferldern wird nicht ausgegeben */
    @Input() public ignoreRequireStar = false;

    /** ein optionales Ergebnis welches mit angezeigt wird */
    @Input() public calculation?: string | null;

    /**
     * Frei wählbarer suffix, nur mit typ CurrencyInputType.SUFFIX
     */
    @Input() public suffix?: string;

    @Input() public isCxadvisory = false;

    public isRequired: boolean | undefined;
    private destroyed$ = new Subject<void>();

    /** default Einstellungen für ein currency input */
    public options: NgxCurrencyConfig = {
        align: 'right',
        allowNegative: false,
        decimal: ',',
        precision: 2,
        prefix: '',
        suffix: ' €',
        thousands: '.',
        allowZero: true,
        nullable: true,
        inputMode: NgxCurrencyInputMode.Natural,
    };

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        switch (this.type) {
            case CurrencyInputType.PERCENT:
                this.options.suffix = ' %';
                this.options.precision = 3;
                break;

            case CurrencyInputType.AREA:
                this.options.suffix = ' m²';
                break;

            case CurrencyInputType.SUFFIX:
                this.options.precision = 0;
                this.options.suffix = this.suffix ?? '';
                break;

            default: break;
        }

        if (!!this.calculation) {
            this.options.align = 'left';
        }

        ValidationService.validatorsChangedObservable$.pipe(
            filter(it => it === this.control),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.updateRequired();
        });

        this.updateRequired();
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.destroyed$.next();
    }

    /**
     * update das isRequired feld anhand der im control rinterlegten validatoren
     */
    private updateRequired() {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }

    // /**
    //  * beim Fokusieren werden die nachkommastellen entfernt
    //  */
    // public onFocusEvent(): void {
    //     if (!this.control.value) {
    //         this.options.precision = 0;
    //     }
    // }

    // /**
    //  * beim verlassen werden die nachkommastellen wieder gesetzt
    //  */
    // public onBlurEvent(): void {
    //     this.options.precision = this.precision;
    // }

    // /**
    //  * beim drücken vom "," werden die nachkommastellen wieder gesetzt
    //  * 
    //  * @param {Event} event key down event
    //  */
    // public onKeyDownEvent(event: KeyboardEvent): void {
    //     if (event.key === this.options.decimal) {
    //         this.options.precision = this.precision;
    //     }
    // }
}
