<div class="wrapper">
    <mat-toolbar class="header mat-elevation-z3 header-toolbar">
        <div fxFlex="33%" fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button type="button" (click)="back()" matTooltip="Zurück zum Unterlagenbereich">
                <span class="material-icons md-36">arrow_back</span>
            </button>
        </div>
        <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center center">
            <span>{{ title }}</span>
        </div>
        <div fxFlex="33%" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button type="button" (click)="download()" matTooltip="Dokument herunterladen">
                <span class="material-icons md-36">file_download</span>
            </button>
        </div>
    </mat-toolbar>

    <waiter-component [isLoading]="!pdfOptions.pdfSrc && !imgOptions?.src && !videoOptions?.src">
        <ngx-extended-pdf-viewer *ngIf="pdfOptions.pdfSrc" [src]="pdfOptions.pdfSrc" [height]="'95vh'"
            pageViewMode="multiple" [showSidebarButton]="false" [showDownloadButton]="false"
            [showOpenFileButton]="false" [showRotateButton]="false"
            [showPresentationModeButton]="false"></ngx-extended-pdf-viewer>

        <div class="content" *ngIf="!pdfOptions.pdfSrc">
            <img *ngIf="imgOptions" class="image-viewer" [src]="imgOptions.src" />

            <video *ngIf="videoOptions" autoplay controls controlsList="nodownload" class="video-viewer">
                <source [src]="videoOptions.src" type="video/mp4">
            </video>
        </div>
    </waiter-component>
</div>