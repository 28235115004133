<mat-dialog-content>
    <div class="header" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="space-around none"
        fxLayoutAlign.gt-xs="space-between center">
        <h2 fxHide fxHide.gt-xs="false"> {{data.debtor.firstName}} {{data.debtor.lastName}} - Unterschrift </h2>
        <h3 fxHide.gt-xs> {{data.debtor.firstName}} {{data.debtor.lastName}} - Unterschrift </h3>
        <button fxFlex="100" fxFlex.gt-xs="40" mat-flat-button (click)="drawClear()" color="secondary">
            {{ 'global.buttons.clean' | translate }}
        </button>
    </div>
    <div class="content">
        <canvas #signatureCanvas [width]="signaturePadOptions.width" [height]="signaturePadOptions.height"> </canvas>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="column-reverse" fxLayout.gt-xs="row" fxLayoutAlign="space-evenly none">
    <button fxFlex="100" fxFlex.gt-xs="40" mat-flat-button (click)="drawCancel()" color="secondary">
        {{ 'global.buttons.cancel' | translate }}
    </button>
    <button fxFlex="100" fxFlex.gt-xs="40" mat-flat-button color="primary" cdkFocusInitial (click)="drawComplete()">
        {{ 'global.buttons.save' | translate }}
    </button>
</mat-dialog-actions>