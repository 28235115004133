import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';

import { IRateCalculationData } from '../../interfaces/rate-calculation-data.interface';
import { CalculationFactoryService, HelperService } from '../../services';
import { GlobalSettings } from '../../utils/global-settings';
import { CurrencyInputType } from '../input-currency/currency-input-type.enum';

/**
 * Komponente, welches einen Ratenrechner bereitstellt
 */
@Component({
    selector: 'ucba-rate-calculation-dialog',
    templateUrl: './rate-calculation-dialog.component.html',
    styleUrls: ['./rate-calculation-dialog.component.scss'],
})
export class RateCalculationDialogComponent implements OnInit, OnDestroy {
    public monthlyRate = 0;
    public readonly minAmount = 10000;
    public readonly maxAmount = 1000000;
    public readonly stepAmount = 100;

    public readonly minDebitRate = 0.125;
    public readonly maxDebitRate = 6;
    public readonly stepDebitRate = 0.125;

    public readonly minDuration = GlobalSettings.minDuration / 12;
    public readonly maxDuration = GlobalSettings.maxDuration / 12;
    public readonly stepDuration = 1;
    public form: FormGroup | undefined;
    public currencyInputType = CurrencyInputType;

    private viewLeft$ = new Subject<void>();

    private clickInterval: number | undefined;

    /**
     * Standard Konstruktor zum initialisieren der default values
     *
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injection
     * @param {IRateCalculationData} data MatDialog injection
     */
    public constructor(
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: IRateCalculationData,
    ) {
        this.form = this.fb.group({
            amount: [
                HelperService.getValue(this.data.amount, 250000),
                Validators.compose([Validators.required, Validators.min(this.minAmount), Validators.max(this.maxAmount)]),
            ],
            debitRate: [
                HelperService.getValue(this.data.debitRate, 1.25),
                Validators.compose([Validators.required, Validators.min(this.minDebitRate), Validators.max(this.maxDebitRate)]),
            ],
            duration: [
                HelperService.getValue(this.data.duration, 25),
                Validators.compose([Validators.required, Validators.min(this.minDuration), Validators.max(this.maxDuration)]),
            ],
        });
    }

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnInit(): void {
        this.monthlyRate = this.calculateMonthlyRate(this.form?.value);

        this.form?.valueChanges.pipe(takeUntil(this.viewLeft$)).subscribe((formData: IRateCalculationData) => {
            this.monthlyRate = this.calculateMonthlyRate(formData);
        });
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * castet ein Abstract Control in ein Formgroup um es im Template besser verwenden zu können
     * 
     * @param {string} controlName control name
     * @returns {FormControl} FormControl
     */
    // eslint-disable-next-line class-methods-use-this
    public asFormControl(controlName: string): FormControl {
        return this.form?.get(controlName) as FormControl;
    }

    /**
     * ändert den Kreditbetrag
     * 
     * @param {number} value Wert des Sliders
     */
    public onChangeCredit(value: number): void {
        this.form?.get('amount')?.patchValue(value !== null ? value : 0);
    }

    /**
     * ändert den Zinssatz
     * 
     * @param {number} value Wert des Sliders
     */
    public onChangeDebitRate(value: number): void {
        this.form?.get('debitRate')?.patchValue(value !== null ? value : 0);
    }

    /**
     * ändert die Laufzeit
     * 
     * @param {number} value Wert des Sliders
     */
    public onChangeCreditDuration(value: number): void {
        this.form?.get('duration')?.patchValue(value !== null ? value : 0);
    }

    /**
     * zur Berechnung der monatlichen Rate
     * 
     * @param {IRateCalculationData} values values
     * @returns {number} monthlyDebitRate
     */
    private calculateMonthlyRate(values: IRateCalculationData): number {
        const comfortProductCalculationService = CalculationFactoryService.productCalculationService(this.data.calculationVersion);
        return values.debitRate > 0 ? comfortProductCalculationService.monthlyDebitRate({
            grossFinancingRequirement: values.amount,
            requestedDebitRate: values.debitRate,
            assumedDuration: values.duration * 12,
            bankAccountFee: this.data.bankAccountFee ?? 0,
            gracePeriod: this.data.gracePeriod,
        })
            : values.duration > 0 ? values.amount / values.duration / 12 + (this.data.bankAccountFee ?? 0)
                : 0;
    }

    /**
     * Startet das kontinuierliche Klicken
     * 
     * @param {string} controlName Name des Input-Feldes
     * @param {string} action Aktion, die durchgeführt werden soll (+ oder -)
     */
    public startContinuousClick(controlName: string, action: string) {
        this.changeInput(controlName, action);
        this.setupClickInterval(() => this.changeInput(controlName, action));
    }

    /**
     * Stoppt das kontinuierliche Klicken
     */
    public stopContinuousClick() {
        this.clearClickInterval();
    }

    /**
     * Setzt das Intervall für kontinuierliches Klicken
     * 
     * @param {Function} actionFn Funktion, die im Intervall ausgeführt werden soll
     */
    private setupClickInterval(actionFn: () => void) {
        this.clickInterval = window.setInterval(actionFn, 200); // wie schnell soll die Aktion wiederholt werden
    }

    /**
     * Löscht das Intervall für kontinuierliches Klicken
     */
    private clearClickInterval() {
        if (this.clickInterval !== undefined) {
            clearInterval(this.clickInterval);
            this.clickInterval = undefined;
        }
    }

    /**
     * Verändert den Wert des Input-Feldes
     * 
     * @param {string} fieldName Name des Input-Feldes, dessen Wert verändert werden soll
     * @param {string} operation Operation, die durchgeführt werden soll (+ oder -)
     */
    private changeInput(fieldName: string, operation: string): void {
        const control = this.form?.get(fieldName);
        if (!control || !operation) { return }

        let stepValue = 0;
        let minValue = 0;
        let maxValue = 0;

        switch (fieldName) {
            case 'amount':
                stepValue = this.stepAmount;
                minValue = this.minAmount;
                maxValue = this.maxAmount;
                break;
            case 'debitRate':
                stepValue = this.stepDebitRate;
                minValue = this.minDebitRate;
                maxValue = this.maxDebitRate;
                break;
            case 'duration':
                stepValue = this.stepDuration;
                minValue = this.minDuration;
                maxValue = this.maxDuration;
                break;
            default:
                break;
        }

        if (operation === 'subtract') {
            const newValue = control?.value - stepValue;
            control.patchValue(Math.max(newValue, minValue));
        } else if (operation === 'add') {
            const lowerBound = Math.max(control.value, minValue);
            const newValue = lowerBound + stepValue;
            control.patchValue(Math.min(newValue, maxValue));
        }
    }
    /**
     * formats the label for slider with currency
     * 
     * @param {number} value value
     * @returns {string} formatted value
     */
    // eslint-disable-next-line class-methods-use-this
    public formatLabelAmount(value?: number): string {
        return `${RateCalculationDialogComponent.formatNumber(2, value)} €`;
    }

    /**
     * formats the label for slider with percentage
     * 
     * @param {number} value value
     * @returns {string} formatted value
     */
    // eslint-disable-next-line class-methods-use-this
    public formatLabelDebitRate(value?: number): string {
        return `${RateCalculationDialogComponent.formatNumber(3, value)} %`;
    }

    /**
     * formats the label for slider with duration
     * 
     * @param {number} value value
     * @returns {string} formatted value
     */
    // eslint-disable-next-line class-methods-use-this
    public formatLabelDuration(value?: number): string {
        return `${RateCalculationDialogComponent.formatNumber(0, value)} Jahre`;
    }

    /**
     * Helper method to format numbers with thousands and decimal separators
     * 
     * @param {number} precision precision
     * @param {number} value value
     * @returns {string} formatted value
     */
    private static formatNumber(precision: number, value?: number): string {
        const parts = (value ?? 0).toFixed(precision).split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return parts.join(',');
    }
}
