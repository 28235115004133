<mat-label *ngIf="cxAdvisory">{{ labelText }}</mat-label>
<mat-form-field *ngIf="!!control" [hideRequiredMarker]="!isRequired" [attr.name]="controlName"
    [appearance]="cxAdvisory ? 'outline' : 'fill'">

    <mat-label *ngIf="!cxAdvisory">{{ labelText }}</mat-label>
    <textarea class="settings" matInput [formControl]="formControl" [rows]="rows" [required]="!!isRequired"
        title="{{ labelText }}" placeholder="{{ labelText }}" [readonly]="isReadOnly" (input)="onTextareaInput($event)"></textarea>

    <mat-error ucba-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    <mat-hint *ngIf="!!comment">
        {{ comment }}
        <ng-container *ngIf="!!comment2">
            <br> {{ comment2 }}
        </ng-container>
    </mat-hint>
</mat-form-field>